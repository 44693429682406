import React from "react";

const ChevronDownSolidIcon = ({ fill = "#fff" }) => {
	return (
		<svg
			width="7"
			height="6"
			viewBox="0 0 7 6"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.48892 1.72232C6.96724 1.06107 6.49478 0.13623 5.67868 0.13623L1.15466 0.13623C0.338555 0.13623 -0.133896 1.06107 0.344419 1.72232L2.60643 4.84944C3.00563 5.40132 3.82771 5.40132 4.22691 4.84944L6.48892 1.72232Z"
				fill={fill}
			/>
		</svg>
	);
};

export default ChevronDownSolidIcon;
