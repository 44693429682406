import React from "react";
import { keyframes } from "theme";
import styled from "styled-components";

const Progressbar = ({ progressbarColor = "green", loadingText = "", logo = null }) => (
	<Container>
		{logo}
		<Bar progressbarColor={progressbarColor} />
		{loadingText && <LoadingText>{loadingText}</LoadingText>}
	</Container>
);

export default Progressbar;

const Container = styled.div`
	width: 100%;
	text-align: center;
	margin: auto;
`;

const ProgressAnimation = keyframes`
     0%   { width: 5%;}
    100% { width: 85%; }
`;

const Bar = styled.div<{ progressbarColor: string }>`
	height: 12px;
	border-radius: 30px;
	background-color: ${(props) => props.progressbarColor};
	transition: 0.4s linear;
	transition-property: width, background-color;
	animation: ${ProgressAnimation} 6s ease-in-out;
	animation-iteration-count: infinite;
	margin: 20px;
`;

const LoadingText = styled.p`
	font-size: 12px;
`;
