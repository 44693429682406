/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from "react";

import {
	Autocomplete,
	AutocompleteProps,
	AutocompleteRenderOptionState,
} from "@material-ui/lab";
import CheckmarkIcon from "assets/svgs/CheckmarkIcon";
import ChevronDownIcon from "assets/svgs/ChevronDownSolidIcon";
import { createStyleVariants, css } from "theme";
import styled from "styled-components";
import TextInput from "components/ThemedComponent/Common/TextInput";

type ThemedAutoCompleteProps = {
	label: string;
	labelKey: string;
	theme?: "light" | "dark";
	renderInput?: (props: any) => ReactNode;
	cta?: ReactNode;
	largePaper?: boolean;
	renderOption?: (option: any, state: AutocompleteRenderOptionState) => React.ReactNode;
	paperProps?: any;
};

const defaultProps: Omit<ThemedAutoCompleteProps, "label" | "labelKey"> = {
	theme: "light",
	renderInput: null,
	cta: null,
	largePaper: false,
	renderOption: null,
	paperProps: {},
};

const ThemedAutoComplete: React.FC<
	ThemedAutoCompleteProps & Omit<AutocompleteProps<any, any, any, any>, "renderInput">
> = ({
	closeIcon,
	labelKey,
	theme = "light",
	label,
	open,
	cta = null,
	largePaper = false,
	renderInput,
	PaperComponent = null,
	popupIcon = null,
	renderOption,
	paperProps = {},
	...props
}) => (
	<Autocomplete
		{...props}
		open={open}
		PaperComponent={
			PaperComponent ||
			// eslint-disable-next-line react/no-unstable-nested-components
			(({ children }) => (
				<Paper largePaper={largePaper} {...paperProps}>
					{children}{" "}
					<div
						onMouseDown={(event) => {
							event.preventDefault();
						}}
					>
						{cta}
					</div>
				</Paper>
			))
		}
		popupIcon={popupIcon || <ChevronDownIcon fill="currentColor" />}
		closeIcon={closeIcon || false}
		renderOption={
			renderOption ||
			((option, otherProps) => (
				<SelectOptionContainer selected={otherProps.selected}>
					<span>
						{(option && option[labelKey]) ||
							option?.name ||
							option?.type ||
							option?.label}
					</span>
					{otherProps.selected ? (
						<CheckMarkIconContainer>
							<CheckmarkIcon fill="currentColor" />
						</CheckMarkIconContainer>
					) : (
						<CheckMarkPlaceholder />
					)}
				</SelectOptionContainer>
			))
		}
		renderInput={
			renderInput ||
			((inputProps) => (
				<TextInput
					inputtheme={theme}
					label={label}
					variant="outlined"
					{...inputProps}
				/>
			))
		}
		blurOnSelect="touch"
	/>
);

ThemedAutoComplete.defaultProps = defaultProps;

export default ThemedAutoComplete;

const Paper = styled.div<{ largePaper: boolean }>`
	background: white;
	border-radius: 8px;
	box-shadow: 2px 4px 14px rgba(0, 0, 0, 0.0474213);
	margin-bottom: 8px;
	margin-top: 8px;
	margin-left: 1px;
	${(props) =>
		props.largePaper &&
		css`
			width: calc(100% + 8px);
			margin-left: -3px;
		`}

	.MuiAutocomplete-option {
		margin-bottom: 0;
	}

	.MuiAutocomplete-option[data-focus="true"] {
		background: transparent;

		span {
			font-weight: ${({ theme }) => theme.fontWeight.semiBold};
			color: ${({ theme }) => theme.colors.gray[800]};
		}
	}

	.MuiAutocomplete-option[aria-selected="true"] {
		background-color: transparent;
	}
`;

const SelectOptionContainer = styled.div<{ selected: boolean }>`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	font-family: ${({ theme }) => theme.fontFamily};
	border-bottom: 1px solid #eaeaec;
	padding-bottom: 10px;

	span {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

const CheckMarkIconContainerVariant = createStyleVariants({
	apna: css`
		background: ${({ theme }) => theme.colors.tertiary};

		svg {
			color: #fafafa;
		}
	`,
	vi: css`
		/* background: ${({ theme }) => theme.colors.tertiary}; */
		background: #ffc600;

		svg {
			color: #2f3043;
		}
	`,

	apnaV2: css`
		background: ${({ theme }) => theme.colors.primary};

		svg {
			color: #fafafa;
		}
	`,
});

const CheckMarkIconContainer = styled.div`
	height: 20px;
	min-width: 20px;
	max-width: 20px;
	border-radius: 5555px;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		height: 8px;
	}

	${(props) => CheckMarkIconContainerVariant(props.theme.vendor)}
`;

const CheckMarkPlaceholder = styled.div`
	height: 20px;
	min-width: 20px;
	max-width: 20px;
	border-radius: 5555px;
	border: 1px solid #d5d6d9;
`;
