/* eslint-disable react/jsx-props-no-spreading */
import { TextField, TextFieldProps } from "@material-ui/core";
import styled from "styled-components";

type ThemedInputProps = {
	inputtheme?: "light" | "dark";
};

type CustomTextInputProps = {
	isError?: boolean;
};
type TextInputProps = TextFieldProps & ThemedInputProps & CustomTextInputProps;
// TODO:: Add Dark color in theme
const TextInput = styled((props) => <TextField {...props} />)<TextInputProps>`
	& {
		width: 100%;
		.MuiFormLabel-root {
			color: ${(props) =>
				props.inputtheme === "dark" ? "#fff" : props.theme.colors.gray[800]};
			font-family: ${({ theme }) => theme.fontFamily};
		}

		.MuiFormLabel-root.Mui-focused {
			color: ${(props) =>
				props.inputtheme === "dark" ? "#fff" : props.theme.colors.gray[800]};
		}

		.MuiInputBase-root {
			/* border: 1px solid green; */
			color: ${(props) => (props.inputtheme === "dark" ? "#fff" : "#000000")};
			font-family: ${({ theme }) => theme.fontFamily};

			fieldset {
				border-color: ${(props) =>
					props.inputtheme === "dark" ? "#fff" : "#000000"};
			}

			&:hover {
				fieldset {
					border-color: ${(props) =>
						props.inputtheme === "dark" ? "#fff" : "#000000"};
				}
			}
		}

		.MuiInputBase-root.Mui-focused {
			color: ${(props) =>
				props.inputtheme === "dark" ? "#fff" : props.theme.colors.gray[800]};

			fieldset {
				border-color: ${(props) =>
					props.inputtheme === "dark" ? "#fff" : "#000000"};
			}
		}

		.MuiAutocomplete-popupIndicator {
			color: ${(props) =>
				props.inputtheme === "dark" ? "#fff" : props.theme.colors.gray[800]};
		}
	}
`;

export default TextInput;
