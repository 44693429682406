/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";

const LABEL_SIDE_OPTIONS = {
	left: "left",
	right: "right",
};

type Props = {
	[x: string]: any;
	label: any;
	labelSide?: string;
	checked: any;
};

const RadioInput: React.FC<Props> = ({
	label,
	labelSide = LABEL_SIDE_OPTIONS.right,
	checked,
	onClick,
	...props
}) => (
	<RadioLabel isSelected={checked} onClick={onClick}>
		{labelSide === LABEL_SIDE_OPTIONS.left && (
			<span className="labelSpan">{label}</span>
		)}
		<Input type="radio" {...props} />
		<CheckMark className="checkmark" />
		{labelSide !== LABEL_SIDE_OPTIONS.left && (
			<span className="labelSpan">{label}</span>
		)}
	</RadioLabel>
);

export default RadioInput;

RadioInput.defaultProps = {
	labelSide: "",
};

const RadioLabel = styled.label<{ inline?: string; isSelected?: boolean }>`
	display: ${(props) => (props.inline ? "inline" : "block")};
	position: relative;
	cursor: pointer;
	font-size: 12px;
	user-select: none;

	display: flex;
	align-items: center;

	span {
		order: 2;
		margin: 5px;
		font-family: ${({ theme }) => (theme.vendor === "apna" ? "Inter" : "Vi")};
		font-size: ${({ theme }) => (theme.vendor === "apna" ? "14px" : "16px")};
		line-height: 20px;
	}

	input:checked {
		font-weight: ${({ theme }) => (theme.vendor === "apna" ? "500" : "bold")};
	}

	${(props) =>
		props.isSelected &&
		`

		.labelSpan {
			font-weight: bold;
		}

		.checkmark::after {
			display: block;
		}
	`}
`;

const Input = styled.input`
	position: absolute;
	opacity: 0;
	cursor: pointer;

	&:focus {
		& + span {
			box-shadow: 0px 0px 0px 4px #859fbc30;
		}
	}

	&:disabled {
		& + span {
			opacity: 0.7;
			cursor: default;
		}
	}
`;

const CheckMark = styled.span`
	order: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 22px;
	width: 22px;
	background-color: transparent;
	border-radius: 50%;
	border: 2px solid #31445a;

	&:hover {
		box-shadow: 0px 0px 0px 4px #859fbc30;
	}

	&::after {
		display: none;
		content: "";
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background: #31445a;
	}
`;
