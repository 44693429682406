const ModalCloseIcon = (props) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
	>
		<g opacity="0.6">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.3786 9.99974L15.5476 5.83079C15.9286 5.4498 15.9286 4.83323 15.5476 4.45225C15.1666 4.07126 14.5492 4.07126 14.169 4.45225L10 8.62119L5.83099 4.45225C5.44999 4.07126 4.8326 4.07126 4.45242 4.45225C4.07142 4.83323 4.07142 5.4498 4.45242 5.83079L8.62062 9.99974L4.45242 14.1687C4.07142 14.5497 4.07142 15.1662 4.45242 15.5472C4.64251 15.7373 4.89272 15.8332 5.1413 15.8332C5.39069 15.8332 5.6409 15.7373 5.83099 15.5472L10 11.3783L14.169 15.5472C14.3591 15.7373 14.6093 15.8332 14.8579 15.8332C15.1073 15.8332 15.3567 15.7373 15.5476 15.5472C15.9286 15.1662 15.9286 14.5497 15.5476 14.1687L11.3786 9.99974Z"
				fill="#2F3042"
			/>
			<mask
				id="mask0_672:19777"
				maskUnits="userSpaceOnUse"
				x="4"
				y="4"
				width="12"
				height="12"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.3786 9.99974L15.5476 5.83079C15.9286 5.4498 15.9286 4.83323 15.5476 4.45225C15.1666 4.07126 14.5492 4.07126 14.169 4.45225L10 8.62119L5.83099 4.45225C5.44999 4.07126 4.8326 4.07126 4.45242 4.45225C4.07142 4.83323 4.07142 5.4498 4.45242 5.83079L8.62062 9.99974L4.45242 14.1687C4.07142 14.5497 4.07142 15.1662 4.45242 15.5472C4.64251 15.7373 4.89272 15.8332 5.1413 15.8332C5.39069 15.8332 5.6409 15.7373 5.83099 15.5472L10 11.3783L14.169 15.5472C14.3591 15.7373 14.6093 15.8332 14.8579 15.8332C15.1073 15.8332 15.3567 15.7373 15.5476 15.5472C15.9286 15.1662 15.9286 14.5497 15.5476 14.1687L11.3786 9.99974Z"
					fill="white"
				/>
			</mask>
		</g>
	</svg>
);

export default ModalCloseIcon;
