import { useEffect } from "react";

import styled from "styled-components";
import { bp, bpMax } from "theme/mediaQuery";
import ModalCloseIcon from "assets/svgs/ModalCloseIcon";
import { Portal } from "react-portal";

export type OverlayProps = {
	showOverlay: boolean;
	onClose: (e: React.MouseEvent) => void;
	image?: JSX.Element;
	title?: string;
	description?: string;
	CTA?: JSX.Element;
	showCloseIcon?: boolean;
};

const Overlay: React.FC<OverlayProps> = ({
	showOverlay,
	onClose,
	image,
	title,
	description,
	CTA,
	showCloseIcon = true,
	children,
}) => {
	useEffect(() => {
		if (showOverlay) {
			document.documentElement.style.overflow = "hidden";
		}

		return () => {
			document.documentElement.style.overflow = "auto";
		};
	}, [showOverlay]);

	if (!showOverlay) {
		return null;
	}

	return (
		<Portal>
			<ModalOverlay onClick={onClose}>
				<Modal>
					{showCloseIcon && (
						<MobileCloseButton data-testid="modal-close-button">
							<ModalCloseIcon />
						</MobileCloseButton>
					)}
					<ModalContent onClick={(event) => event.stopPropagation()}>
						{showCloseIcon && (
							<CloseButton onClick={onClose}>
								<ModalCloseIcon />
							</CloseButton>
						)}
						{image}
						<Title>{title}</Title>
						<Description>{description}</Description>
						{CTA}
						{children}
					</ModalContent>
				</Modal>
			</ModalOverlay>
		</Portal>
	);
};

const ModalOverlay = styled.div`
	position: fixed;
	background: rgba(84, 84, 84, 0.46);
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 9999;
`;

const Modal = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	position: absolute;
	z-index: 99;

	${bp.md} {
		width: 500px;
	}

	${bpMax.md} {
		bottom: 0;
		width: 100%;
	}
`;

const ModalContent = styled.div`
	width: 100%;
	padding: 30px 30px 44px;
	border-radius: 8px 8px 0 0;
	font-family: ${(props) => props.theme.fontFamily};
	background: ${(props) => props.theme.colors.backgroundPrimary};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	${bp.md} {
		border-radius: 8px;
	}
`;

const MobileCloseButton = styled.div`
	margin: 0 16px 10px 0;
	width: 32px;
	height: 32px;
	padding: 6px;
	border-radius: 50%;
	background: #d5d6d9;
	display: none;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	${bpMax.md} {
		display: flex;
	}
`;

const CloseButton = styled.div`
	margin-bottom: 10px;
	width: 32px;
	height: 32px;
	padding: 6px;
	border-radius: 50%;
	background: #d5d6d9;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	align-self: flex-end;

	${bpMax.md} {
		display: none;
	}
`;

const Title = styled.h3`
	margin: 30px 0 8px;
	color: ${(props) => props.theme.colors.primary};
	font-weight: ${(props) => props.theme.fontWeight.semiBold};
	line-height: 24px;
	font-size: ${(props) => props.theme.fontSize.xl};
	text-align: center;
`;

const Description = styled.p`
	margin: 0 0 30px;
	color: #6b7c8f;
	font-weight: ${(props) => props.theme.fontWeight.normal};
	line-height: 14px;
	font-size: ${(props) => props.theme.fontSize.xs};
	text-align: center;
`;

export default Overlay;
